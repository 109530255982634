import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import posthogJS from "posthog-js";
import "./global.css";
import { useEffect } from "react";

export const ErrorBoundary = () => {
  const error = useRouteError();
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const location = useLocation();
  useEffect(() => {
    posthogJS.capture("$pageview");
  }, [location]);

  return (
    <html lang="en" className="h-full bg-white">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full dark:bg-zinc-900">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
